@import-normalize;

@import 'ag-grid-community/src/styles/ag-grid';
@import 'ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';

.app {
  .ag-theme-one {
    .ag-root-wrapper {
      background-color: transparent;

      .ag-header {
        background-color: transparent;
        border-width: 1px;
        @apply text-heading-01;
      }

      .ag-row {
        background-color: transparent;
        @apply py-1 text-body-01;
      }

      .ag-row-hover {
        background-color: transparent;
      }
    }
  }
}
